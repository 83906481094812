var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 bg-light d-block map-container" },
    [
      _c(
        "l-map",
        {
          ref: "map",
          staticClass: "h-100 w-100",
          attrs: { zoom: _vm.zoom, center: _vm.center },
          on: {
            ready: function($event) {
              return _vm.doSomethingOnReady()
            }
          }
        },
        [
          _vm._l(_vm.tileProviders, function(tileProvider) {
            return _c("l-tile-layer", {
              key: tileProvider.name,
              attrs: {
                name: tileProvider.name,
                visible: tileProvider.visible,
                url: tileProvider.url,
                attribution: tileProvider.attribution,
                "layer-type": "base"
              }
            })
          }),
          [
            _c("l-control-layers", { attrs: { position: "topright" } }),
            _c("l-control", { attrs: { position: "topleft" } }, [
              _vm.inDrawingMode
                ? _c("button", { on: { click: _vm.toggleDrag } }, [
                    _vm._v(" " + _vm._s(_vm.drag ? "drag" : "draw") + " ")
                  ])
                : _vm._e(),
              _vm.inDrawingMode && _vm.drawnPolygons.length
                ? _c("button", { on: { click: _vm.lockPolygonGroup } }, [
                    _vm._v(" lock Drawn Polygons ")
                  ])
                : _vm._e(),
              _vm.inDrawingMode && _vm.drawnPolygons.length
                ? _c("button", { on: { click: _vm.eraseTempPolygons } }, [
                    _vm._v(" erase ")
                  ])
                : _vm._e()
            ]),
            _c(
              "l-control",
              { attrs: { position: "bottomright" } },
              [
                !_vm.inManualMode
                  ? _c(
                      "CButton",
                      {
                        attrs: { color: "success" },
                        on: { click: _vm.addGeofenceClicked }
                      },
                      [_vm._v(" Add ")]
                    )
                  : _vm._e(),
                _vm.inSelectMode && _vm.hasActiveGeofence && !_vm.infoCard
                  ? _c(
                      "CButton",
                      {
                        attrs: { id: "show-detail-btn", color: "primary" },
                        on: { click: _vm.showDetailClicked }
                      },
                      [_vm._v(" Info ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            !_vm.infoCard
              ? _c(
                  "l-control",
                  { attrs: { position: "bottomleft" } },
                  [
                    _vm.inSelectMode && _vm.hasActiveGeofence
                      ? _c(
                          "CBadge",
                          {
                            staticClass: "m-1 p-2",
                            attrs: { id: "active-info", color: "warning" }
                          },
                          [
                            _c(
                              "span",
                              { ref: "active-info", staticClass: "flash" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.activeGeofenceName) + " "
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.previewCircularGeofence
              ? [
                  _c("l-marker", {
                    attrs: {
                      "lat-lng": _vm.previewCircularGeofence.getLatLngCasted(),
                      icon: _vm.getIcon(_vm.previewCircularGeofence.getUId())
                    }
                  }),
                  _c("l-circle", {
                    ref: _vm.previewCircularGeofence.getUId(),
                    attrs: {
                      "lat-lng": _vm.previewCircularGeofence.getLatLngCasted(),
                      radius: _vm.previewCircularGeofence.getRadius(),
                      color: "brown"
                    }
                  })
                ]
              : _vm._e(),
            _vm._l(_vm.circularGeofencesFiltered, function(geofence) {
              return [
                _c("l-marker", {
                  key: "m" + geofence.getUId(),
                  attrs: {
                    "lat-lng": geofence.getLatLngCasted(),
                    icon: _vm.getIcon(geofence.getUId())
                  },
                  on: {
                    mouseover: function($event) {
                      _vm.circularOnHover(geofence.getUId())
                    },
                    mouseleave: _vm.onLeave,
                    click: function($event) {
                      _vm.circularOnClick(geofence.getUId())
                    }
                  }
                }),
                _c("l-circle", {
                  key: "c" + geofence.getUId(),
                  ref: geofence.getUId(),
                  refInFor: true,
                  attrs: {
                    "lat-lng": geofence.getLatLngCasted(),
                    radius: geofence.getRadius(),
                    color: _vm.getFillColor(geofence.getUId(), _vm.CIRCULAR)
                  },
                  on: {
                    mouseover: function($event) {
                      _vm.circularOnHover(geofence.getUId())
                    },
                    mouseleave: _vm.onLeave,
                    click: function($event) {
                      _vm.circularOnClick(geofence.getUId())
                    }
                  }
                })
              ]
            }),
            _vm.tempPolygons
              ? _vm._l(_vm.tempPolygons.getPolygons(), function(polygon) {
                  return _c("l-polygon", {
                    key: polygon.getId(),
                    attrs: {
                      "lat-lngs": polygon.getPointsCasted(),
                      fillColor: _vm.tempGetFillColor(polygon.getId()),
                      color: _vm.tempGetColor(polygon.getId())
                    },
                    on: {
                      click: function($event) {
                        _vm.polygonOnClick(
                          _vm.tempPolygons.getId(),
                          polygon.getId()
                        )
                      },
                      mouseover: function($event) {
                        _vm.polygonOnHover(_vm.tempPolygons.getId())
                      },
                      mouseleave: _vm.onLeave
                    }
                  })
                })
              : _vm._e(),
            _c("l-free-draw", {
              attrs: { mode: _vm.freeDrawMode },
              model: {
                value: _vm.drawnPolygons,
                callback: function($$v) {
                  _vm.drawnPolygons = $$v
                },
                expression: "drawnPolygons"
              }
            })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }