<template>
  <div class="w-100 bg-light d-block map-container">
    <l-map
      ref="map"
      class="h-100 w-100"
      :zoom="zoom"
      :center="center"
      
      @ready="doSomethingOnReady()"
    >
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />

      <template>
        <l-control-layers position="topright"></l-control-layers>
        <l-control position="topleft">
          <button v-if="inDrawingMode" @click="toggleDrag">
            {{drag? "drag" : "draw"}}
          </button>
          <button v-if="inDrawingMode && drawnPolygons.length" @click="lockPolygonGroup">
            lock Drawn Polygons
          </button>
          <button v-if="inDrawingMode && drawnPolygons.length" @click="eraseTempPolygons">
            erase
          </button>
        </l-control>
        <l-control position="bottomright">
          <CButton v-if="!inManualMode" color="success" @click="addGeofenceClicked" >
            Add
            <!-- <i class="fas fa-home"></i> -->
          </CButton>
          <CButton 
            id="show-detail-btn" 
            v-if="inSelectMode && hasActiveGeofence && !infoCard" 
            color="primary"
            @click="showDetailClicked"
          >
            Info
            <!-- <i class="fas fa-home"></i> -->
          </CButton>
        </l-control> 
        <l-control position="bottomleft" v-if="!infoCard">
          <CBadge 
            id="active-info" 
            v-if="inSelectMode && hasActiveGeofence" 
            class="m-1 p-2"
            color="warning"
          >
          <span ref="active-info" class="flash"> {{activeGeofenceName}} </span>
          </CBadge>
        </l-control>

        <template v-if="previewCircularGeofence">
          <l-marker :lat-lng="previewCircularGeofence.getLatLngCasted()" :icon="getIcon(previewCircularGeofence.getUId())" 
          />
          <l-circle 
            :ref="previewCircularGeofence.getUId()"
            :lat-lng="previewCircularGeofence.getLatLngCasted()" 
            :radius="previewCircularGeofence.getRadius()" 
            color="brown" 
          />
        </template>

        <template v-for="geofence in circularGeofencesFiltered">
          <l-marker :key="'m' + geofence.getUId()" :lat-lng="geofence.getLatLngCasted()" :icon="getIcon(geofence.getUId())" 
            @mouseover="circularOnHover(geofence.getUId())"
            @mouseleave="onLeave"
            @click="circularOnClick(geofence.getUId())"
          />
          <l-circle 
            :ref="geofence.getUId()"
            :key="'c' + geofence.getUId()" 
            :lat-lng="geofence.getLatLngCasted()" 
            :radius="geofence.getRadius()" 
            :color="getFillColor(geofence.getUId(), CIRCULAR)" 
            @mouseover="circularOnHover(geofence.getUId())"
            @mouseleave="onLeave"
            @click="circularOnClick(geofence.getUId())"
          >
          </l-circle>
        </template>

        <template v-if="tempPolygons">
          <l-polygon v-for="polygon in tempPolygons.getPolygons()" :key="polygon.getId()"
            :lat-lngs="polygon.getPointsCasted()"
            @click="polygonOnClick(tempPolygons.getId(), polygon.getId())"
            @mouseover="polygonOnHover(tempPolygons.getId())"
            @mouseleave="onLeave"
            :fillColor="tempGetFillColor(polygon.getId())"
            :color="tempGetColor(polygon.getId())"
          />
        </template>
        
        <!-- <template v-for="group in polygonGroups">
          <l-polygon 
            v-for="polygon in group.getPolygons()" 
            :key="polygon.getId()"
            :lat-lngs="polygon.getPointsCasted()" 
            @click="polygonOnClick(group.getId(), polygon.getId())"
            @mouseover="polygonOnHover(group.getId())"
            @mouseleave="onLeave" 
            :fillColor="getFillColor(polygon.getId(), POLYGON)"
            :color="getColor(polygon.getId(), POLYGON)"/>
        </template> -->
        <l-free-draw v-model="drawnPolygons" :mode="freeDrawMode" />
        <!-- <l-marker :lat-lng="marker" :icon="getIcon()" 
          @mouseover="toggleActiveIcon(true)"
          @mouseleave="toggleActiveIcon(false)" 
        /> -->
      </template>
    </l-map>
  </div>
</template>

<style scoped>
.map-container {
  margin: 0;
  padding: 2px;
  display: block;
}
</style>

<script>
import { mapGetters } from 'vuex'
import {
  LMap,
  LControlLayers,
  LTileLayer,
  LMarker,
  LIconDefault,
  LCircle,
  LIcon,
  LPopup,
  LTooltip,
  LPolygon,
  LControl
} from "vue2-leaflet";
import LFreeDraw from 'vue2-leaflet-freedraw';
import { NONE, ALL } from 'leaflet-freedraw';
// import "leaflet-sidebar";
import { Point, Polygon, PolygonGroup, generateRandomId } from '@/lib/geofencing_lib';
import { geofenceType, geoListViewMode } from "@/lib/geofencing_lib";
import { eventBus } from '@/main'
import { tileProviders } from "@/lib/geofencing_lib/leafletMapSetup"


const defaultCoord = [49.2700935, -122.985266];

// Layers
// /v1/mapbox/light-v10.h
// Satelite: mapbox/satellite-streets-v11

const getDefault = () => defaultCoord;
export default {
  name: "LeafletMap",
  components: {
    LMap,
    LControlLayers,
    LTileLayer,
    LMarker,
    LIconDefault,
    LCircle,
    LIcon,
    LPopup,
    LTooltip,
    LFreeDraw,
    LPolygon,
    LControl,
  },
  props: {
    allowScroll: {
      type: Boolean,
      default: true,
    },
    popup: {
      type: String,
    },
    //data: Object,
    center: { type: Array, default: getDefault },
    activeRowMarker: { type: Array, default: getDefault },
    defaultZoom: { type: Number, default: 13 },
    activePolygonId: {type: String, default: null},
    activeCircularUID: {default: null},
    editingMode: {type: String, default: geoListViewMode.SELECT},
    activeGroupId: {type: String, default: null},
    unlockedGroupId: {type: String, default: null},
    filteredCircularGeofencesUIds: {type: Array},
    //center: Array,
    //marker: Array,
    infoCard: {
      type: Boolean,
    }
  },
  data() {
    return {
      zoom: 13,
      path: "/images/",
      //bounds:[],
      

      _url: "https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png", //"https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //url: 	"https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", // 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      //marker: [47.41322, -1.219482]
      //marker: [49.2700935, -122.985266],
      radius: 200,
      id: "mapbox/light-v10", //"mapbox/satellite-streets-v11",
      token:
        "pk.eyJ1Ijoia2hvbGgiLCJhIjoiY2t4ZDRxd2R0M2dkZDJvbXU5bzRzbmI5dSJ9.Lrxf7iqYogpyBpnwFo9HiQ", //"pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      mapOptions: {
        zoomSnap: 1,
        tileSize: 256,
        //scrollWheelZoom: false,
        //draggable: false,
        trackResize: true,
      },
      drawnPolygons: [ // array of array of {lat, lng}
      ],
      iconActive: false,
      onHoverId: null,
      drag: false,
      POLYGON: geofenceType.POLYGON,
      CIRCULAR: geofenceType.CIRCULAR, 
    };
  },

  computed: {
    tileProviders(){
      return tileProviders();
    }, 
    freeDrawMode() {
      if (this.editingMode == geoListViewMode.DRAWING && !this.drag) {
        return ALL;
      } else {
        return NONE;
      }
    },

    ...mapGetters({
      polygonGroups: 'polygonGroups',
      tempPolygons: "tempPolygons",
      circularGeofences: "circularGeofences",
      previewCircularGeofence: "previewCircularGeofence"
    }),

        
    circularGeofencesFiltered () {
      return this.circularGeofences.filter(geo => {
        return this.filteredCircularGeofencesUIds.includes(geo.getUId());
      })
    },

    inDrawingMode () {
      return this.editingMode == geoListViewMode.DRAWING;
    },

    inSelectMode () {
      return this.editingMode == geoListViewMode.SELECT;
    },

    inManualMode () {
      return this.editingMode == geoListViewMode.MANUAL;
    },

    showCenter() {
      return !this.geofenceModeActivated;
    },

    hasActiveGeofence() {
      return this.activeCircularUID || this.groupId;
    },

    activeGeofence () {
      return this.circularGeofences.find(geo => {
        return geo.getUId() == this.activeCircularUID;
      })
    },

    activeGeofenceName () {
      return this.activeGeofence ? this.activeGeofence.getName() : "";
    }
  },
  mounted: function () {
    this.zoom = this.defaultZoom;
    eventBus.$on("triggerAFlash", () => {
      const el = this.$refs['active-info'];
      if (el) {
        /* a hacky way to trigger reflow and replay animation
        /* https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element */
        el.style.animation = 'none';
        el.offsetHeight; 
        el.style.animation = null; 
      }
    })
  },
  beforeDestroy: function() {
    eventBus.$off('triggerAFlash');
  },
  methods: {
    showDetailClicked () {
      this.$emit("showDetailClicked");
    },

    addGeofenceClicked() {
      this.$emit('addGeofenceClicked');
    },

    polygonOnClick (groupId, polyId) {
      if (this.inDrawingMode) {
        if (groupId != this.unlockedGroupId) {
          return;
        } else {
          this.$emit('setActivePolygon', groupId, polyId);
        }
      } else if (this.inManualMode){
        return;
      } else {
        this.$emit('setActivePolygon', groupId, polyId);
      }
    },

    polygonOnHover (id) {
      this.onHoverId = id;
    }, 

    circularOnHover (uid) {
      if (this.inDrawingMode) {
        return;
      } else if (this.inManualMode) {
        return;
      } else {
        this.onHoverId = uid;
      }
    },

    circularOnClick (uid) {
      if (this.inSelectMode) {
        this.$emit('setActiveCircular', uid);
      }
    },

    onLeave () {
      this.onHoverId = null;
    },
    toggleDrag () {
      this.drag = !this.drag;
    },
    getFillColor (id, type) {
      let activeId;
      if (type == geofenceType.POLYGON) {
        activeId = this.activePolygonId;
      } else if (type == geofenceType.CIRCULAR) {
        activeId = this.activeCircularUID;
      } else {
        console.error("unsupported geofence type", type);
      }

      if (id == activeId) {
        if (this.inSelectMode) {
          return "#03c04a"; // lightgreen
        } else if (this.inDrawingMode) {
          return "yellow"; // h_todo_0023_01_a1: decide the drawing color logic
        } else if (this.inManualMode) {
          return "green";
        }
      } else {
        if (this.inSelectMode) {
          return "blue";
        } else if (this.inDrawingMode) {
          return "grey"; // h_todo_0023_01_a1: decide the drawing color logic
        } else if (this.inManualMode) {
          return "grey";
        }     
      }
    },
    getColor (id, type) {
      let activeId;
      if (type == this.POLYGON) {
        activeId = this.activePolygonId;
      } else if (type == this.CIRCULAR) {
        activeId = this.activeCircularUID;
      } else {
        console.error("unsupported geofence type", type);
      }

      if (this.inDrawingMode) {
        return "grey";
      }
      if (this.inSelectMode) {
        if (id == activeId) {
          return "green";
        }
        return "blue";
      }
      if (this.inManualMode) {
        if (id == activeId) {
          return "green";
        }
        if (id == this.onHoverId) {
          return "red";
        }
        return "grey";
      }
      console.error("unsupported mode", this.editingMode);
    },
    
    // get the fill color for the polygons that are unlocked. 
    tempGetFillColor (id) {
      if (id == this.activePolygonId) {
        if (!this.inManualMode) {
          return "yellow"; // h_todo_0023_01_a1: decide the drawing color logic
        } else {
          return "green";
        }
      } else {
        if (!this.inManualMode) {
          return "#add8e6";
        } else {
          return "grey"
        }
      }
    },

    tempGetColor (id) {
      if (id == this.onHoverId) { // h_todo_0012_06_a1: manualActiveEdit
        if (!this.manualActiveEdit || id == this.activePolygonId) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "#add8e6";
      }
    },

    toggleActiveIcon(bool) {
      this.iconActive = bool;
    },
    getIcon(uid) { // h_ms_01
      let iconUrl;
      let color;

      if (uid == this.activeCircularUID) {
        if (uid == this.onHoverId) {
          color = "yellow";
        } else {
          color= "green";
        }
        iconUrl = `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`;
      } else {
        if (uid == this.onHoverId) {
          color = "yellow";
        } else {
          color= "blue";
        }
        iconUrl = `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`;
      }

      let icon = new L.Icon({
        iconUrl,
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      return icon;
    },

    doSomethingOnReady() {
      this.$nextTick(() => {
        console.log("Map Loaded");
        this.$refs.map.mapObject.invalidateSize();
        this.$refs.map.mapObject.zoom = this.zoom;

        this.$refs.map.mapObject.mapOptions = this.mapOptions;
      });
    },

    lockPolygonGroup() {
      // const center = this.$refs.map.mapObject.getCenter();
      // const zoom = this.$refs.map.mapObject.getZoom(); 
      this.$store.commit("uploadPolygonsFromDrawing", this.tempPolygons); // h_todo_0008_a1: upload to API instead of store. 
      this.$store.commit("updateTempPolygons", null);  
      // this.$refs.map.mapObject.setView(center, zoom);
      this.$emit('switchMode');
      this.drawnPolygons = [];
    },

    eraseTempPolygons () {
      this.drawnPolygons = [];
    },

    cast(importedPolygon) {
      let onePoly = [];
      importedPolygon.points.forEach(point => {
        onePoly.push({
          lat: point.lat,
          lng: point.lng,
        })
      })
      return onePoly;
    },

    isCircularActive (uid) {
      return this.activeCircularUID == uid;
    }
  },
  watch: {
    drawnPolygons(_newGroup) {
      this.$emit('setActivePolygon', null);

      let polygons = [];
      _newGroup.forEach(_polygon => {
        let points = [];
        _polygon.forEach(_latlng => {
          points.push(new Point(generateRandomId(), _latlng.lat, _latlng.lng));
        })
        polygons.push(new Polygon(generateRandomId(), points));
      })
      // h_todo_0022_03_a1: new PolygonGroup needs to pass in proper parameters
      this.$store.commit("updateTempPolygons", new PolygonGroup(this.unlockedGroupId, null, polygons, [], null, null, null));  // h_todo_0008_a1: upload to API instead of store. 
    },

    // set the bounds to the active geofence (now only supports circular) h_todo: support polygon
    activeGeofence() { // h_ms_01
      this.$nextTick(() => {
        if (this.activeCircularUID) {
          const bounds = this.$refs[this.activeCircularUID][0].mapObject.getBounds();
          this.$refs.map.mapObject.fitBounds(bounds);
        }
      })
    },

    previewCircularGeofence () {
      this.$nextTick(() => {
        if (this.previewCircularGeofence) {
          const bounds = this.$refs[this.previewCircularGeofence.getUId()].mapObject.getBounds();
          this.$refs.map.mapObject.fitBounds(bounds);
        }
      })
    }

  },
};
</script>

<style>
#active-info {
  font-size: 12px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#active-info:hover {
  max-width: 240px;
}

.flash {
  animation: blinker 1s linear;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
