var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardBody",
                    {
                      staticClass: "h-100",
                      staticStyle: { "min-height": "400px" }
                    },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("LeafletMap", {
                                ref: "map",
                                staticClass: "h-100",
                                staticStyle: { "min-height": "300px" },
                                attrs: {
                                  center: _vm.center,
                                  marker: _vm.center,
                                  defaultZoom: _vm.zoomLevel
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c(
                                "CBadge",
                                { attrs: { color: "secondary" } },
                                [
                                  _c("CGeocode", {
                                    attrs: {
                                      "v-if": _vm.coordinates,
                                      lat: _vm.center[0],
                                      lon: _vm.center[1]
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c(
                "CForm",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c(
                            "CCard",
                            [
                              _c(
                                "CCardBody",
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        [
                                          _c("CInput", {
                                            attrs: {
                                              type: "plain-text",
                                              label: "Description",
                                              value: _vm.address,
                                              readonly: _vm.readOnly
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { md: "6" } },
                                        [
                                          _c("CSelect", {
                                            staticClass: "pb-1 mb-0",
                                            attrs: {
                                              enabled: !_vm.readOnly,
                                              label: "Type",
                                              options: _vm.types,
                                              value: _vm.computedType,
                                              readonly: _vm.readOnly
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                _vm.computedType = $event
                                              }
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "prepend-content",
                                                fn: function() {
                                                  return [
                                                    _c("CIcon", {
                                                      attrs: { name: "cil-map" }
                                                    })
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CCol",
                                        { attrs: { md: "6" } },
                                        [
                                          _c("CInput", {
                                            attrs: {
                                              type: "plain-text",
                                              label: "Tags",
                                              value: _vm.computedTags,
                                              readonly: _vm.readOnly
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }