<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody class="h-100" style="min-height: 400px">
            <CRow>
              <CCol>
                <LeafletMap
                  ref="map"
                  style="min-height: 300px"
                  class="h-100"
                  :center="center"
                  :marker="center"
                  :defaultZoom="zoomLevel"
                >
                </LeafletMap>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CBadge color="secondary">
                  <CGeocode
                    :v-if="coordinates"
                    :lat="center[0]"
                    :lon="center[1]"
                  />
                </CBadge>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CForm>
          <CRow>
            <CCol>
              <CCard>
                <CCardBody>
                  <CRow>
                    <CCol>
                      <CInput
                        type="plain-text"
                        label="Description"
                        :value="address"
                        :readonly="readOnly"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CSelect
                      :enabled="!readOnly"
                        label="Type"
                        class="pb-1 mb-0"
                        :options="types"
                        :value.sync="computedType"
                        :readonly="readOnly"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-map"
                        /></template>
                      </CSelect>
                    </CCol>
                    <CCol md="6">
                      <CInput
                        type="plain-text"
                        label="Tags"
                        :value="computedTags"
                        :readonly="readOnly"
                      />
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import LeafletMap from "@/components/dashboard/LeafletMap";
import CGeocode from "@/components/base/CGeocode";

const defaultCoord = [49.2700935, -122.985266];
export default {
  name: "GeofenceView",
  props: {
    type: {
      type: String,
    },
    coordinates: {
      type: Object || Array,
    },
    mapSrc: {
      type: String,
    },
    address: {
      type: String,
    },
    tags: {
      type: String || Array,
    },
  },
  components: {
    LeafletMap,
    CGeocode,
  },
  data() {
    return {
      readOnly: true,
      types: [
        { label: "Radius", value: "radius" },
        { label: "Polygon", value: "polygon" },
      ],
    };
  },
  computed: {
    center() {
      if (this.coordinates) {
        return [this.coordinates.lat, this.coordinates.lng];
      } else {
        return defaultCoord;
      }
    },
    marker() {
      if (this.coordinates) {
        return [this.coordinates.lat, this.coordinates.lng];
      }
      return defaultCoord;
    },
    zoomLevel() {
      return 14;
    },
    computedTags() {
      if (this.tags) {
        let temp = this.getTags(this); 

        return temp; 
      }
    },
    computedType: {
      get() {
        if (this.tags) {
          return this.tags.toString();
        }
      },
      set(val){
          // TODOL 
      }
    },
  },

  methods: {
    getTags(item) {
      try {
        let sp = item.tags.split(",");
        if (sp) sp = sp.map((item) => item.trim());
        return sp;
      } catch (err) {}
    },
  },
};
</script>
